import React from 'react'

export default function NotificationSection() {
    return (
        <div className='notification-box w-[372px] h-[calc(70vh_-_86px)]  px-[20px] py-[30px] rounded-[20px] bg-pure-black absolute right-[20px] bottom-[55px] overflow-scroll flex flex-col gap-[28px]'>
            <div className='notification-time flex items-center justify-between'>
                <div>Today</div>
                <button className='text-xs text-accent-color'>Mark all as Read</button>
            </div>
            <div className='notification-items-wrap'>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quasi culpa, dignissimos praesentium debitis vero!
                        </div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
            </div>
            <div className='notification-time flex items-center justify-between'>
                <div>This Week</div>
            </div>
            <div className='notification-items-wrap'>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
                <div className='notification-item'>
                    <div className='img-wrap'>
                        <img src='/images/ruffy.jpg' alt='' />
                    </div>
                    <div className='content-wrap'>
                        <div className='notification'>New ID has joined</div>
                        <div className='time-ago'>27 minutes ago</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
