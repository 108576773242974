import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'

// import PrivateRoute from './components/auth/private-route'
import PublicRoute from './components/auth/public-route'
import { AdminPrivateRoute } from './components/auth/private-route'
import { OrgPrivateRoute } from './components/auth/private-route'
import AdminLayout from './components/common/admin-layout'
import { store } from './store/store'

const Login = lazy(() => import('./pages/login/login'))
const Registration = lazy(() => import('./pages/registration/registration'))
const VerifyOtp = lazy(() => import('./pages/verify-otp/verify-otp'))
const LandingPage = lazy(() => import('./pages/landing-page/landing-page'))
const MedalCount = lazy(() => import('./pages/medal-count/medal-count'))
const ParticipationLevel = lazy(() => import('./pages/participation-level/participation-level'))
const AdminLogin = lazy(() => import('./pages/admin/admin-login'))
const AllOrganisations = lazy(() => import('./pages/admin/all-organisations'))
const AllStudents = lazy(() => import('./pages/admin/all-students'))
const RequestAccess = lazy(() => import('./pages/admin/request-access'))

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    {/* <Route path='/' element={<Layout />}> */}
                    <Route
                        path='/'
                        element={
                            <OrgPrivateRoute>
                                <Suspense fallback={<></>}>
                                    <LandingPage />
                                </Suspense>
                            </OrgPrivateRoute>
                        }
                    />
                    <Route
                        path='/login'
                        element={
                            <PublicRoute>
                                <Suspense fallback={<></>}>
                                    <Login />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path='/admin/login'
                        element={
                            <PublicRoute>
                                <Suspense fallback={<></>}>
                                    <AdminLogin />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path='/registration'
                        element={
                            <PublicRoute>
                                <Suspense fallback={<></>}>
                                    <Registration />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path='/verify-otp'
                        element={
                            <PublicRoute>
                                <Suspense fallback={<></>}>
                                    <VerifyOtp />
                                </Suspense>
                            </PublicRoute>
                        }
                    />
                    <Route
                        path='/medal-count'
                        element={
                            <OrgPrivateRoute>
                                <Suspense fallback={<></>}>
                                    <MedalCount />
                                </Suspense>
                            </OrgPrivateRoute>
                        }
                    />
                    <Route
                        path='/participation-level'
                        element={
                            <OrgPrivateRoute>
                                <Suspense fallback={<></>}>
                                    <ParticipationLevel />
                                </Suspense>
                            </OrgPrivateRoute>
                        }
                    />
                    <Route path='/' element={<AdminLayout />}>
                        <Route
                            path='/admin'
                            element={
                                <AdminPrivateRoute>
                                    <Suspense fallback={<></>}>
                                        <AllOrganisations />
                                    </Suspense>
                                </AdminPrivateRoute>
                            }
                        />
                        <Route
                            path='/admin/all-students'
                            element={
                                <AdminPrivateRoute>
                                    <Suspense fallback={<></>}>
                                        <AllStudents />
                                    </Suspense>
                                </AdminPrivateRoute>
                            }
                        />
                        <Route
                            path='/admin/request-access'
                            element={
                                <AdminPrivateRoute>
                                    <Suspense fallback={<></>}>
                                        <RequestAccess />
                                    </Suspense>
                                </AdminPrivateRoute>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    )
}
