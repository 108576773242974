import { requests } from '../services/requests'

export const ENVS = {
    node_tiger_base_url: process.env.REACT_APP_NODE_TIGER_API_URL,
    sportkeyz_base_url: process.env.REACT_APP_SPORTKEYZ_API_URL,
    axios_timeout: process.env.REACT_APP_AXIOS_TIMEOUT,
    react_app_url: process.env.REACT_APP_URL,
}

export const NODE_TIGER_URL = ENVS.node_tiger_base_url
export const SPORTKEYZ_URL = ENVS.sportkeyz_base_url

export const API_URLS = Object.freeze({
    NODE_TIGER: Object.freeze({
        'send-otp': '/send-otp',
        'verfiy-otp': '/verify-otp',
        'portal-signin': '/portal-signin',
        'portal-signup': '/portal-signup',
        'organization-registration': '/organization-registration',
        'admin-login': '/admin/login',
    }),
    SPORTKEYZ: Object.freeze({
        requests: '/organizations/requests',
        'request-approval': '/organizations/status',
        'org-count': '/organizations/total-organizations',
        'student-count': '/organizations/total-students',
        'get-organization': '/organizations/dashboard',
        'get-students': '/organizations/students',
        'student-detail': '/organizations/student-detail',
        'organizations-actions': '/organizations/filter',
        'students-actions': '/organizations/students/filter',
        'medal-count': '/organizations/students/medal-count',
        'organizations-export': '/organizations/exports',
        'students-export': '/organizations/student-exports',
    }),
})

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const NAME_REGEX = /^(?=.*[a-zA-Z]).+$/
