import { useEffect } from 'react'
import { cn } from '../../utils/cn'

export default function Modal({ handleClose = () => {}, containerClassName, children, overlayClassName, isOpen }) {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                handleClose()
            }
        }

        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown)
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [isOpen, handleClose])

    if (!isOpen) return null

    const handleOverlayClick = (event) => {
        event.stopPropagation()
        handleClose()
    }

    const handleModalClick = (event) => {
        event.stopPropagation()
    }

    return (
        <div
            onClick={handleOverlayClick}
            className={cn('fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-[1]', overlayClassName)}>
            <div
                onClick={handleModalClick}
                className={cn(
                    'bg-secondary-bg h-[194px] w-[320px] m-auto py-[30px] px-[20px] rounded-[20px] flex flex-col justify-between fixed',
                    containerClassName
                )}>
                {children}
            </div>
        </div>
    )
}
