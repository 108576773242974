import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { requests } from '../../services/requests'

import { ReactComponent as HeaderLogo } from '../../icons/header-logo.svg'
import { ReactComponent as ReqIcon } from '../../icons/request-icon.svg'
import { ReactComponent as ActiveReqIcon } from '../../icons/active-request-icon.svg'

export default function AdminHeader() {
    const [data, setData] = useState([])
    const len = data?.organizations?.length

    useEffect(() => {
        fetchOrgPendingList()
    }, [])

    const fetchOrgPendingList = () => {
        requests((resData) => {
            setData(resData)
        })
    }

    return (
        <div className='header-wrap pt-[25px] pb-[12px]'>
            <header>
                <div className='container flex justify-between items-center'>
                    <div className='logo-wrap '>
                        <NavLink to={'/admin'} className='flex justify-between items-center gap-[30px] text-4xl font-semibold'>
                            <HeaderLogo />
                            SportsKeyz
                        </NavLink>
                    </div>
                    <div className='admin-header-menu-wrap flex justify-between items-center gap-[40px]'>
                        <NavLink to={'/admin'} end className='rounded-[30px]'>
                            All Organizations
                        </NavLink>
                        <NavLink to={'/admin/all-students'} end className='rounded-[30px]'>
                            All Students
                        </NavLink>
                        <NavLink to={'/admin/request-access'} end className='rounded-[30px] flex gap-[10px] items-center'>
                            {len === 0 ? <ReqIcon /> : <ActiveReqIcon />}
                            Request Accesss
                        </NavLink>
                    </div>
                </div>
            </header>
        </div>
    )
}
