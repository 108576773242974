import React, { useCallback, useState } from 'react'

import LogOutModal from './logout-modal'
import NotificationSection from './notification-section'

import { ReactComponent as BellIcon } from '../../icons/bell-icon.svg'
import { ReactComponent as LogoutIcon } from '../../icons/logout-icon.svg'

export default function Footer() {
    const [isLogOutOpen, setIsLogOutOpen] = useState(false)
    const [notificationOpen, setNotificationOpen] = useState(false)

    const handleLogoutToggle = useCallback(() => {
        setIsLogOutOpen((prevState) => !prevState)
    }, [])

    const handleNotificationToggle = useCallback(() => {
        setNotificationOpen((prevState) => !prevState)
    }, [])

    return (
        <div className='footer-wrap pt-[12px] pb-[17px]'>
            <footer>
                <div className='container flex gap-[10px] justify-end items-center relative'>
                    <button
                        className='notification-btn w-[38px] h-[38px] rounded-[50%] bg-secondary-bg grid place-items-center '
                        onClick={handleNotificationToggle}>
                        <BellIcon />
                    </button>
                    <button
                        className='logout-btn w-[38px] h-[38px] rounded-[50%] bg-secondary-bg grid place-items-center '
                        onClick={handleLogoutToggle}>
                        <LogoutIcon />
                    </button>
                    <LogOutModal isLogOutOpen={isLogOutOpen} handleToggle={handleLogoutToggle} />
                    {notificationOpen && <NotificationSection />}
                </div>
            </footer>
        </div>
    )
}
