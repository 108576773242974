import axios from 'axios'
import { ENVS } from '../const'
import toast from 'react-hot-toast'
import { cookie } from './cookie'

export const AxiosInstance = (baseURL) => {
    const instance = axios.create({
        baseURL: baseURL ?? ENVS.node_tiger_base_url,
        timeout: ENVS.axios_timeout,
        headers: {},
    })
    return instance
}

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    failedQueue = []
}

export const SecureAxiosInstance = (baseURL) => {
    const instance = axios.create({
        baseURL: baseURL ?? ENVS.node_tiger_base_url,
        timeout: ENVS.axios_timeout,
        headers: {},
    })
    instance.interceptors.request.use(
        (config) => {
            const token = cookie.get('access-token')
            config.headers = {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`,
            }
            return config
        },
        (error) => {
            Promise.reject(error)
        }
    )
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = error.config
            if (error.response) {
                if (error.response.status === 401) {
                    if (isRefreshing) {
                        return new Promise(function (resolve, reject) {
                            failedQueue.push({ resolve, reject })
                        })
                            .then((token) => {
                                originalRequest.headers.Authorization = `Bearer ${token}`
                                return axios(originalRequest)
                            })
                            .catch((err) => {
                                return Promise.reject(err)
                            })
                    }
                    // console.log('Token expired, refreshing...')
                    isRefreshing = true

                    return new Promise((resolve, reject) => {
                        const refreshToken = cookie.get('refresh-token')

                        instance
                            .post(`${ENVS.node_tiger_base_url}/token`, {
                                grant_type: 'refresh_token',
                                refresh_token: refreshToken,
                            })
                            .then(({ data }) => {
                                // console.log('Token refreshed!')
                                cookie.set('access-token', data.accessToken)
                                cookie.set('refresh-token', data.refreshToken)

                                instance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`

                                originalRequest.headers.Authorization = `Bearer ${data.accessToken}`
                                processQueue(null, data.accessToken)
                                resolve(axios(originalRequest))
                            })
                            .catch((err) => {
                                // console.log('Token refresh failed!')
                                logout()

                                processQueue(err, null)
                                reject(err)
                            })
                            .finally(() => {
                                isRefreshing = false
                            })
                    })
                }
                if (error.response.status === 422) {
                    toast.error(error.response.data.message)
                }
                if (error.response.status === 400) {
                    logout()
                }
            }
            return Promise.reject(error)
        }
    )
    return instance
}

// const clearCookieAndLogout = () => {
//     const isAdmin = cookie.get('isAdmin')
//     setTimeout(() => {
//         cookie.remove('access-token')
//         cookie.remove('refresh-token')
//         if (isAdmin) {
//             cookie.remove('isAdmin')
//         } else {
//             cookie.remove('spid')
//         }
//     }, 0)
// }

const logout = () => {
    const isAdmin = cookie.get('isAdmin')

    cookie.remove('access-token')
    cookie.remove('refresh-token')
    cookie.remove('isAdmin')
    cookie.remove('spid')

    window.location.href = isAdmin ? '/admin/login' : '/login'
}
