import { API_URLS, ENVS } from '../const'
import { SecureAxiosInstance } from '../lib/axios'

export const requests = async (query = { page: 1 }, onSuccess, onError) => {
    const url = new URLSearchParams({ page: query.page ?? 1 })

    try {
        const res = await SecureAxiosInstance(ENVS.sportkeyz_base_url).get(`${API_URLS.SPORTKEYZ['requests']}?${url}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            },
        })

        if (res.data?.status === 'success') {
            onSuccess?.(res.data)
        } else {
            onError?.(res.data)
        }
    } catch (err) {
        onError?.(err)
    }
}
