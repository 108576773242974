import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const verifyOtp = createAsyncThunk('otp/verify', async () => {
    const response = await axios.get('https://auth.zahansports.com/verify-otp')
    return response.data.spid
})

const otpSlice = createSlice({
    name: 'otp',
    initialState: { spid: null },
    reducers: {
        setSpid(state, action) {
            state.spid = action.payload.spid
        },
    },
    extraReducers: (builder) => {
        builder.addCase(verifyOtp.fulfilled, (state, action) => {
            state.spid = action.payload.spid
        })
    },
})

export default otpSlice.reducer
export const action = otpSlice.actions
