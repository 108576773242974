import React, { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import Modal from './modal'
import { cookie } from '../../lib/cookie'

const LogOutModal = ({ isLogOutOpen, handleToggle }) => {
    const navigate = useNavigate()

    const logOut = useCallback(() => {
        const isAdmin = cookie.get('isAdmin')
        cookie.remove('access-token')
        cookie.remove('refresh-token')
        toast.success('Logged Out Successfully!', {
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                maxWidth: 'unset',
            },
        })
        if (isAdmin) {
            cookie.remove('isAdmin')
            navigate('/admin/login')
        } else {
            cookie.remove('spid')
            navigate('/login')
        }
        handleToggle()
    }, [handleToggle, navigate])

    return (
        <Modal
            isOpen={isLogOutOpen}
            handleClose={handleToggle}
            containerClassName={'bg-secondary-bg h-[194px] w-[320px] m-auto py-[30px] px-[20px] rounded-[20px] flex flex-col justify-between '}>
            <p className='text-[22px] font-[300] text-center text-[rgba(255,255,255,0.7)] leading-[1.2]'>Are you sure you want to Logout?</p>
            <div className='btn-wrap flex justify-between'>
                <button
                    className='bg-pure-black h-[52px] w-[125px] text-center font-semibold leading-[1.2] p-[15px] rounded-[100px]'
                    onClick={logOut}>
                    Confirm
                </button>
                <button
                    onClick={handleToggle}
                    className='bg-[#F94B60] h-[52px] w-[125px] text-center font-semibold leading-[1.2] p-[15px] rounded-[100px]'>
                    Cancel
                </button>
            </div>
        </Modal>
    )
}

export default LogOutModal
