import React from 'react'
import { Outlet } from 'react-router-dom'
import { cookie } from '../../lib/cookie'

import AdminHeader from '../../pages/admin/admin-header'
import Footer from './footer'

export default function AdminLayout() {
    const accessToken = cookie.get('access-token')

    return (
        <div>
            {accessToken && <AdminHeader />}
            <main>
                <Outlet />
            </main>
            {accessToken && <Footer />}
        </div>
    )
}
