import React from 'react'
import { Navigate } from 'react-router-dom'
import { cookie } from '../../lib/cookie'

export function OrgPrivateRoute({ children }) {
    const isAdmin = cookie.get('isAdmin')
    const accessToken = cookie.get('access-token')
    if (isAdmin) {
        return <Navigate to='/admin' />
    }
    return accessToken ? children : <Navigate to='/login' />
}

export function AdminPrivateRoute({ children }) {
    const isAdmin = cookie.get('isAdmin')
    const accessToken = cookie.get('access-token')
    if (!isAdmin) {
        return <Navigate to='/' />
    }
    return accessToken ? children : <Navigate to='/admin/login' />
}
